<template>
    <app-layout>
        <template v-slot:header>
            <Header :title="$t('payment_approvals')"
                    :isColumns="false" :is-filter="false">
            </Header>
        </template>
        <template v-slot:header-mobile>
            <header-mobile :title="$t('payment_approvals')"
                           :isColumns="false" :is-filter="false">
            </header-mobile>
        </template>

        <div>

            <ValidationObserver ref="form">
                <b-row>
                    <b-col cols="3">
                        <ValidationProvider name="student_number" v-slot="{errors}" :rules="['add','delete'].includes(form.process)">
                            <b-form-group :label="$t('student_numbers')">
                                <multi-text v-model="form.student_numbers"
                                            :validate-error="errors[0]"
                                            :only-number-end-enter="true"/>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col cols="9">
                    </b-col>
                    <b-col cols="12">
                        <ValidationProvider name="process" v-slot="{errors}" rules="required">
                            <multi-selectbox :options="options" :multiple="false"
                                             :validate-error="errors[0]"
                            v-model="form.process"></multi-selectbox>
                        </ValidationProvider>
                    </b-col>
                    <b-col cols="12" class="mt-2">
                        <div class="d-flex">
                            <b-button variant="primary" class="mr-2" @click="startTheProcess">{{ $t('start_process') }}</b-button>
                            <b-button variant="primary" class="mr-2" @click="download">{{ $t('excel') }}</b-button>
                        </div>
                    </b-col>
                </b-row>
            </ValidationObserver>
        </div>
    </app-layout>

</template>

<script>

import Header from "@/layouts/AppLayout/Header.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";

import MultiText from "@/components/interactive-fields/MultiText.vue";
import PreventCourseRegistration from "@/services/PreventCourseRegistration";

import qs from "qs";
import {ValidationProvider, ValidationObserver} from "vee-validate"

export default {
    name: "advisorBulk",
    components: {
        MultiText,
        HeaderMobile,
        AppLayout,
        Header,
        ValidationProvider, ValidationObserver
    },
    data() {
        return {
            form: {},
            options: [
                {value: 'add', text: this.$t('add_student_number')},
                {value: 'delete', text: this.$t('delete_student_number')},
                {value: 'all', text: this.$t('add_all_student_without_payment_approval')},
                {value: 'delete_all', text: this.$t('delete_all')},
            ]
        }
    },
    methods: {

        startTheProcess()
        {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_start_process'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.start();
                    }
                })
        },
        async start() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                let studentNumbers = [];
                if(this.form.student_numbers){
                    studentNumbers = this.form.student_numbers.split(/\n/);
                }
                if(this.form.process == 'all'){
                    PreventCourseRegistration.store({'type':'payment'})
                        .then((response) => this.$toast.success(response.data.data.length+' kayıt eklendi'))
                        .catch(err => this.showErrors(err, this.$refs.form))
                }
                else if(this.form.process == 'add'){
                    if(studentNumbers.length==0){
                        return;
                    }
                    PreventCourseRegistration.store({'type':'payment', 'student_numbers': studentNumbers})
                        .then((response) => {
                            this.$toast.success(response.data.data.length+' kayıt eklendi');
                        })
                        .catch(err => this.showErrors(err, this.$refs.form))
                }
                else if(this.form.process == 'delete'){
                    if(studentNumbers.length==0){
                        return;
                    }
                    PreventCourseRegistration.del({'type':'payment', 'student_numbers': studentNumbers})
                        .then((response) => this.showMessage(response))
                        .catch(err => this.showErrors(err, this.$refs.form))
                }
                else if(this.form.process == 'delete_all'){
                    PreventCourseRegistration.del({'type':'payment'})
                        .then((response) => this.showMessage(response))
                        .catch(err => this.showErrors(err, this.$refs.form))
                }
            }
        },
        download(){
            PreventCourseRegistration.excel({params:{type:'payment'}})
            .then((response) => {
                this._downloadFile(response, 'Student-List-Without-PaymentApproval.xlsx')
            });
        }
    },
}
</script>
